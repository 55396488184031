import focusWithin from 'focus-within';
import { $, $$, isPositionStickySupported, wrap } from './helper/utils';
import outline from './helper/outline';
import { MOBILMEDIA } from './helper/constants';
import inView from './helper/inView';

focusWithin(document);
outline();

const sliderMap = new Map();
const sliderDragging = new Map();

export default function () {
  let mobileNavLoaded = false;

  const loadMobileNav = () => {
    if ($('.mobile-nav') !== null) {
      import('./components/offcanvas.js')
        .then((offcanvas) => {
          mobileNavLoaded = true;
          const mobileNav = $('.mobile-nav');
          const showMenuBtn = $('.show-menu');
          const mobileMenu = offcanvas.default(mobileNav, showMenuBtn);

          showMenuBtn.addEventListener('click', mobileMenu.toggle, { passive: true });
        }).catch((err) => {
          console.warn(`Chunk offcanvas.js loading failed: ${err}`);
        });
    } else {
      mobileNavLoaded = true;
    }
  };

  MOBILMEDIA.addListener((e) => {
    if (e.matches) {
      if (mobileNavLoaded === false) loadMobileNav();
    }
  });

  if (MOBILMEDIA.matches) {
    loadMobileNav();
  }

  // if ($('.siema') !== null) {
  //   import('./components/slider.js')
  //     .then(slider => slider.default(sliderMap, sliderDragging))
  //     .catch((err) => {
  //       console.warn('Chunk slider.js loading failed', err);
  //     });
  // }

  const lightboxes = $$('.lightbox');
  inView(lightboxes, () => {
    import('./components/lightbox.js')
      .then(lightbox => lightbox.default('.lightbox', sliderMap, sliderDragging))
      .catch((err) => {
        console.warn('Chunk lightbox.js loading failed', err);
      });
  }, true);

  if (
    $('main a[href^="#"]:not([data-toggle])') !== null
    || $('main button[data-scroll]') !== null
  ) {
    const isSmoothScrollSupported = 'scrollBehavior' in document.documentElement.style;
    const importScripts = [];

    if (isSmoothScrollSupported === false) {
      importScripts.push(import('smoothscroll-polyfill'));
    }

    importScripts.push(import('./components/smoothScroll.js'));

    Promise.all(importScripts)
      .then((values) => {
        console.log(values);

        if (isSmoothScrollSupported === false) values[0].polyfill();
        values[importScripts.length - 1].default();
      })
      .catch((err) => {
        console.warn('Chunk smoothScroll.js loading failed', err);
      });
  }

  if ($('.counter-wrapper .counter') !== null) {
    import('./components/animations/counter.js')
      .then((counter) => {
        const counterElements = $$('.counter-wrapper .counter');
        const triggerElements = $$('.counter-wrapper > div');
        counter.default(counterElements, triggerElements);
      })
      .catch((err) => {
        console.warn('Chunk /animations/counter.js loading failed', err);
      });
  }

  // if ($('.team-member') !== null) {
  //   import('./components/teamMember.js')
  //     .then(teamMember => teamMember.default())
  //     .catch((err) => {
  //       console.warn('Chunk teamMember.js loading failed', err);
  //     });
  // }

  if ($('.form-group > .form-line') !== null) {
    import('./components/form.js')
      .then(form => form.default())
      .catch((err) => {
        console.warn('Chunk form.js loading failed', err);
      });
  }

  const bsElements = $$('.nav-tabs, [data-toggle="collapse"]');
  inView(bsElements, () => {
    const fadeTab = (e) => {
      const href = e.relatedTarget.getAttribute('href');
      TweenMax.fromTo(href, 0.3, { opacity: 0 }, { opacity: 1, ease: Linear.easeNone });
    };

    import('../libs/bs-4-native')
      .then((bsn) => {
        if ('bootstrapNative' in window === false) {
          window.bootstrapNative = bsn;
        }
        const tabs = $$('.nav-tabs .tab-link');
        for (let i = 0; i < tabs.length; i++) {
          const el = tabs[i];

          // fade in new tab content
          el.addEventListener('hide.bs.tab', fadeTab, false);
        }
      })
      .catch((err) => {
        console.warn('Chunk bs4-native.js loading failed', err);
      });
  }, true);

  if ($$('.table-head').length > 0 && isPositionStickySupported() === false) {
    import('stickyfilljs')
      .then((Stickyfill) => {
        const elements = $$('.table-head');
        const wrapper = [];

        for (let i = 0; i < elements.length; i++) {
          const el = elements[i];
          const wrapperEl = document.createElement('div');
          wrapperEl.classList.add('table-head');
          el.classList.remove('table-head');
          wrap(el, wrapperEl);
          wrapper.push(wrapperEl);
        }

        Stickyfill.add(wrapper);
      })
      .catch((err) => {
        console.warn('Chunk stickyfilljs loading failed', err);
      });
  }

  if ($('.covid-notice') !== null) {
    import('./components/covidNotice.js')
      .then(covidNotice => covidNotice.default())
      .catch((err) => {
        console.warn('Chunk covidNotice.js loading failed', err);
      });
  }
}
